import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from '@core/components/header/header.component';

@Component({
  selector: 'pxc-login-layout',
  templateUrl: './login-layout.component.html',
  standalone: true,
  imports: [RouterModule, HeaderComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginLayoutComponent {}
