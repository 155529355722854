import { TuiRoot, TuiAlert, TuiDialog } from '@taiga-ui/core';
import { Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

@Component({
  selector: 'pxc-root',
  standalone: true,
  imports: [RouterOutlet, TuiRoot],
  templateUrl: './app.component.html',
})
export class AppComponent {
  private readonly appInsights = inject(ApplicationInsights);
  private readonly titleService = inject(Title);
  private readonly title = 'Mon suivi de plaie';

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  isBrowserIE = window.document.documentMode;

  constructor() {
    this.titleService.setTitle(this.title);
    this.appInsights.loadAppInsights();
    this.appInsights.trackPageView();
  }
}
