import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
  inject,
} from '@angular/core';
import {
  AbstractControl,
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from '@core/authentication/services/authentication.service';
import { TitleComponent } from '@shared/components/title/title.component';
import { TuiAlertService, TuiButton } from '@taiga-ui/core';
import { TuiButtonLoading } from '@taiga-ui/kit';
import { TuiInputPasswordModule } from '@taiga-ui/legacy';
import { BehaviorSubject } from 'rxjs';
import { AuthenticationApiService } from '../services/authentication.api.service';

@Component({
  selector: 'pxc-login',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TuiButton,
    TuiButtonLoading,
    TuiInputPasswordModule,
    TitleComponent,
  ],
  templateUrl: './login.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent implements OnInit {
  private router = inject(Router);

  @Inject(TuiAlertService)
  private readonly alertService = inject(TuiAlertService);
  private readonly formBuilder = inject(UntypedFormBuilder);
  private readonly authenticationService = inject(AuthenticationService);
  private readonly authenticationApiService = inject(AuthenticationApiService);

  actionOnGoing$!: BehaviorSubject<boolean>;
  loginForm!: UntypedFormGroup;

  get inputPassword(): AbstractControl | null {
    return this.loginForm.get('inputPassword');
  }

  ngOnInit(): void {
    this.actionOnGoing$ = new BehaviorSubject(false);
    this.loginForm = this.formBuilder.group({
      inputPassword: new UntypedFormControl('', [
        Validators.required,
        Validators.maxLength(256),
      ]),
    });
  }

  login(): void {
    if (!this.loginForm.valid) {
      this.alertService
        .open('Merci de résoudre les erreurs de saisie.', {
          label: 'Erreur de validation',
        })
        .subscribe();

      return;
    }

    this.actionOnGoing$.next(true);
    this.authenticationApiService
      .authenticate(
        this.inputPassword?.value,
        this.authenticationService.getTeledepositToken(),
      )
      .subscribe({
        next: ({ token }) => {
          this.authenticationService.setBearerToken(token);
          this.actionOnGoing$.next(false);
          this.router.navigate(['teledeposit']);
        },
        error: (err) => {
          this.actionOnGoing$.next(false);
          let title = '';
          let message = '';
          if (err.status === 400 || err.status === 404) {
            title = 'Identifiants incorrects';
            message = 'Les identifiants renseignés sont incorrects.';
          } else {
            console.error(err);
            title = 'Erreur système';
            message =
              "Une erreur s'est produite, merci de réessayer plus tard.";
          }
          this.alertService
            .open(message, {
              label: title,
            })
            .subscribe();
        },
      });
  }
}
