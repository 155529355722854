
<main
  class="overflow-x-hidden min-h-screen relative transition animate-in fade-in bg-slate-50 flex flex-col"
>
  <pxc-header
    class="sticky top-0 z-30 pointer-events-none bg-white"
    data-testid="navbar"
  ></pxc-header>

  <div
    class="animate-in fade-in slide-in-from-top-1 container max-w-lg flex flex-col justify-center flex-1 relative"
  >
    <div
      class="absolute blur-3xl bg-pixablue-2/10 w-64 h-full max-h-80 rounded-full -translate-x-40 -translate-y-32"
    ></div>
    <div
      class="absolute blur-3xl bg-pixapurple-2/10 rounded-full w-64 h-full max-h-80 translate-x-32"
    ></div>
    <div
      class="absolute blur-3xl bg-pixared-2/10 w-full max-w-sm h-64 rounded-full translate-y-40"
    ></div>
    <router-outlet data-testid="content"></router-outlet>
  </div>
</main>
