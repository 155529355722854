import { Routes } from '@angular/router';
import { LoginLayoutComponent } from '@core/authentication/login-layout/login-layout.component';
import { LoginComponent } from '@core/authentication/login/login.component';
import { MainLayoutComponent } from '@core/components/main-layout/main-layout.component';
import { DepositTokenErrorComponent } from '@core/errors/deposit-token-error/deposit-token-error.component';
import { depositKeyGuard } from '@core/guards/deposit-key.guard';
import { isAuthenticatedUserGuard } from '@core/guards/is-authenticated-user.guard';
import { TeledepositService } from '@features/teledeposit/services/teledeposit.service';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    canActivate: [depositKeyGuard],
    component: LoginLayoutComponent,
    children: [
      {
        path: '',
        component: LoginComponent,
      },
    ],
  },
  {
    path: 'deposit-token-error',
    component: LoginLayoutComponent,
    children: [
      {
        path: '',
        component: DepositTokenErrorComponent,
      },
    ],
  },
  {
    path: 'teledeposit',
    canActivate: [depositKeyGuard, isAuthenticatedUserGuard],
    providers: [TeledepositService],
    component: MainLayoutComponent,
    loadChildren: () =>
      import('./features/teledeposit/teledeposit.routes').then((m) => m.routes),
  },
];
