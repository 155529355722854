import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationApiService {
  private httpClient = inject(HttpClient);

  public authenticate(password: string, token?: string) {
    return this.httpClient.post<any>(
      `${environment.apiBaseUrl}/deposit/authenticate`,
      {
        password,
        token,
      },
    );
  }
}
